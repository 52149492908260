<template>
  <div>
    <form-wizard
      id="user-invite-wizard"
      @on-change="changeTab"
      shape="tab"
      color="#6c757d"
      v-if="isCompany"
    >
      <template #title>
          &nbsp;
      </template>
    <template slot="footer" slot-scope="props">
      <hr />
      <div class="wizard-footer-left">
        <wizard-button
          v-if="props.activeTabIndex > 1 && !props.isLastStep"
          class="btn btn-primary"
          @click.native="props.prevTab()"
        >
          Indietro
        </wizard-button>
      </div>
      <div class="wizard-footer-right">
        <wizard-button
          v-if="props.activeTabIndex === 0"
          @click.native="props.nextTab()"
          class="btn btn-warning"
        >
          Prosegui
        </wizard-button>
        <wizard-button
          v-if="props.activeTabIndex === 1"
          @click.native="props.nextTab()"
          :before-change="validateRequest"
          class="btn btn-warning"
        >
          Invia richiesta
        </wizard-button>
        <wizard-button
          v-if="props.isLastStep"
          @click.native="closeModal"
          class="btn btn-success"
        >
          Chiudi
        </wizard-button>
      </div>
    </template>
    <tab-content
      title="Avviso"
      icon="fas fa-hand-paper"
      class="content-invest"
    >
      <div class="text-center">
        <p>
          Questo procedure permette di invitare un altro utente a collaborare con l'azienda.
          <br />
          La persona indicata riceverà permessi operativi e la possibilità di agire sulla
          piattaforma a nome dell'azienda selezionata.
        </p>
        <div class="d-flex">
          <div class="bg-gray-200 p-3 my-3 d-flex align-items-center rounded-2 m-auto">
            <div class="font-size--xxl me-1">
              <font-awesome-icon icon="building" class="fa-fw" />
            </div>
            <h4 class="mb-0">
              {{companyName}}
            </h4>
          </div>
        </div>
      </div>
    </tab-content>
    <tab-content
      title="Dati soggetto"
      icon="fas fa-user"
      lazy
      :before-change="validateRequest"
    >
      <p class="mb-3">
        Inseresci l'email dell'utente che vuoi invitare a collaborare.
      </p>
      <b-row>
        <b-col md="6">
          <field-text
            title="Email"
            fieldId="userEmal"
            placeholder="Email"
            :state="validateState($v.formInvite.userEmail)"
            v-model="$v.formInvite.userEmail.$model"
            :errorMessage="decodeError($v.formInvite, 'userEmail')"
          />
        </b-col>
        <b-col md="6">
          <field-select
            title="Rapporto di collaborazione"
            :options="relationTypes"
            :state="validateState($v.formInvite.relationType)"
            v-model="$v.formInvite.relationType.$model"
          ></field-select>
        </b-col>
      </b-row>
      <div class="mt-3 pt-4 px-3 mx-n3 border-top">
        <p>
          E' possible impostare una data di fine validità per la collaborazione.
          <br />
          Una volta abilitata, viene impostata di default a 30gg. La durata minima accettata è di 15gg.
        </p>
        <div class="mt-3">
          <b-form-checkbox
            v-model="$v.formInvite.expirable.$model"
            name="check-button"
            switch
            v-on:change="prepareDate"
          >
            Imposta data scadenza
          </b-form-checkbox>
        </div>
        <div v-if="formInvite.expirable" class="mt-3">
          <field-calendar
            title="Scadenza collaborazione"
            :state="validateState($v.formInvite.expireDate)"
            v-model="$v.formInvite.expireDate.$model"
            :errorMessage="decodeError($v.formInvite, 'expireDate')"
          ></field-calendar>
        </div>
      </div>
    </tab-content>
    <tab-content
      title="Inviata"
      icon="fas fa-paper-plane"
    >
      <div v-if="submitted">
        <loader-info/>
      </div>
      <div v-else>
        <div v-if="success" class="text-center">
          <font-awesome-icon icon="check-circle" class="text-success fa-5x my-3" />
          <h6 class="mt-3">
            Richiesta inviata con successo a:
            <br />
            <strong>
              {{formInvite.userEmail}}
            </strong>
          </h6>
        </div>
        <div v-else class="text-center">
          <font-awesome-icon icon="exclamation-circle" class="text-danger fa-5x my-3" />
          <h6 class="mt-3">
            Si è verificato un errore con la richiesta.
            <br />
            Verificare che non sia già presente nell'elenco delle collaborazioni.
          </h6>
        </div>
      </div>
    </tab-content>
    </form-wizard>
    <div v-else>
      <p class="my-3 text-center">
        <em>
          E' possibile invitare a collaborare solo con aziende
        </em>
      </p>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import email from 'vuelidate/lib/validators/email';
import { required, requiredIf } from 'vuelidate/lib/validators';
import {
  decodeFormFieldError,
  isNotEmpty,
  validateFormFieldState,
} from '@/utils/validators';

const FieldText = () => import('@/components/fields/FieldText.vue');
const FieldCalendar = () => import('@/components/fields/FieldCalendar.vue');
const LoaderInfo = () => import('@/components/helpers/LoaderInfo.vue');
const FieldSelect = () => import('@/components/fields/FieldSelect.vue');

export const validDate = (value, form) => {
  if (form.expirable) {
    const age = moment().diff(form.expireDate, 'days');
    console.log('form', form, age);
    return age < 15;
  }
  return true;
};

export default {
  name: 'UserInviteWizard',
  components: {
    FieldSelect, LoaderInfo, FieldCalendar, FieldText,
  },
  props: {
    onCloseModal: Function,
  },
  data() {
    return {
      formInvite: {
        userEmail: '',
        relationType: null,
        expirable: false,
        expireDate: null,
      },
      submitted: false,
      relationContext: 'company_connection',
      success: false,
    };
  },
  validations: {
    formInvite: {
      userEmail: {
        email,
        required,
      },
      relationType: {
        required,
      },
      expirable: {

      },
      expireDate: {
        required: requiredIf((form) => form.expirable),
        validDate,
      },
    },
  },
  computed: {
    isCompanyProfile() {
      const currentProfile = this.$store.getters['subject/currentCompany'];
      return isNotEmpty(currentProfile);
    },
    activityStatus() {
      if (this.isCompanyProfile) {
        return this.$store.getters['subject/activityCompanyStatus'];
      }
      return this.$store.getters['subject/activityPersonStatus'];
    },
    isOperative() {
      return this.activityStatus.operative;
    },
    isCompany() {
      const currentProfile = this.$store.getters['subject/currentCompany'];
      return isNotEmpty(currentProfile);
    },
    company() {
      const currentProfile = this.$store.getters['subject/currentCompany'];
      return currentProfile || {};
    },
    companyName() {
      return this.company?.info?.companyName || 'N.A.';
    },
    relationTypes() {
      return this.$store.getters['tableUtils/getTableList'](this.relationContext) || [];
    },
  },
  mounted() {
    if (!this.isOperative) this.$router.replace('/');
    if (!this.$store.getters['tableUtils/hasTableList'](this.relationContext)) {
      if (!this.$store.getters['tableUtils/isLoadingTableList'](this.relationContext)) {
        this.$store.dispatch('tableUtils/loadTableList', {
          tableName: this.relationContext,
          language: 'it',
        });
      }
    }
  },
  methods: {
    validateState: validateFormFieldState,
    decodeError: decodeFormFieldError,
    changeTab(oldIndex, newIndex) {
      console.log('newIndex', newIndex);
      if (newIndex === 2) {
        const {
          userEmail, expireDate, relationType,
        } = this.formInvite;
        const { relationContext } = this;
        this.submitted = true;
        this.success = false;
        const { taxCode } = this.company.info;
        this.$store.dispatch('subject/createUserInvitation', {
          userEmail, expireDate, relationContext, relationType, taxCode,
        })
          .then((response) => {
            this.success = response?.data || false;
            this.submitted = false;
          })
          .catch((error) => {
            console.log('invitation error', error);
            this.success = false;
            this.submitted = false;
          });
      }
    },
    validateRequest() {
      return !this.$v.formInvite.$invalid;
      // return this.isEmailInValid && this.isExpireDateValid && this.isRelationTypeValid;
    },
    prepareDate(value) {
      console.log('prepareDate', value);
      if (value) {
        let start = moment();
        start = start.add(30, 'days');
        this.formInvite.expireDate = start.toISOString(true);
      } else {
        this.formInvite.expireDate = null;
      }
    },
    closeModal() {
      if (this.onCloseModal) {
        this.formInvite.userEmail = '';
        this.formInvite.relationType = null;
        this.formInvite.expirable = false;
        this.formInvite.expireDate = null;
        this.submitted = false;

        this.onCloseModal();
      }
    },
  },
};
</script>

<style scoped>

</style>
